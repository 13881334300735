import "./mega-menu-footer"
import {headRoomHeader} from "./../main-header"

const megaMenu = document.querySelector(".mega-menu")
const body = document.querySelector("body")
const mainHeaderSearchButton = document.querySelector(".main-header__mobile-buttons")
const mobileQuery = window.matchMedia("(max-width: 47.999em)")
const mainNavOpenButton = document.querySelector(".main-header__main-nav-open")
const mainNavCloseButtons = document.querySelectorAll(".main-header__main-nav-close")

const openButtons = document.querySelectorAll(".--js-main-nav-open")

const openMenu = (event: Event) => {
  event?.preventDefault()
  megaMenu?.classList.add("--open")
  headRoomHeader?.pin()
  headRoomHeader?.freeze()

  mainHeaderSearchButton?.classList.add("--hidden")
  mainNavOpenButton?.classList.add("--hidden")
  mainNavCloseButtons.forEach((closeButton) => closeButton.classList.remove("--hidden"))

  if (mobileQuery.matches) {
    body?.style.setProperty("overflow", "hidden")
    body?.setAttribute("sroll", "no")
  }
}

const closeMenu = (event: Event) => {
  event?.preventDefault()
  megaMenu?.classList.remove("--open")
  headRoomHeader?.unfreeze()
  mainHeaderSearchButton?.classList.remove("--hidden")
  body?.style.setProperty("overflow", null)
  body?.removeAttribute("sroll")

  mainNavCloseButtons.forEach((closeButton) => closeButton.classList.add("--hidden"))
  mainNavOpenButton?.classList.remove("--hidden")
}

openButtons.forEach((openButton) => {
  openButton.addEventListener("click", (event) => openMenu(event))
})

const closeButtons = document.querySelectorAll(".--js-main-nav-close")

closeButtons.forEach((closeButton) => {
  closeButton.addEventListener("click", (event) => closeMenu(event))
})
