import Headroom from "headroom.js"

const mainHeader = document.querySelector(".main-header")
const mobileQuery = window.matchMedia("(max-width: 47.999em)")

const headRoomHeaderOptions = {
  offset: mainHeader?.clientHeight ?? 0,
}

export const headRoomHeader = mainHeader ? new Headroom(mainHeader, headRoomHeaderOptions) : null

if (mainHeader !== null) {
  headRoomHeader?.init()

  const mainContent = document.querySelector("main")

  const resizeObserver = new ResizeObserver(() => {
    mainContent?.style.setProperty("padding-top", `0px`)
    if (!mainHeader.classList.contains("--dark") || mobileQuery.matches) {
      mainContent?.style.setProperty("padding-top", `${mainHeader.clientHeight}px`)
      const userSectionMenu = document.querySelector<HTMLElement>('body[data-layout="user-section"] .user-section-menu')
      userSectionMenu?.style.setProperty("padding-top", `${mainHeader.clientHeight}px`)
    }

    document.querySelector<HTMLElement>("body, html")?.style.setProperty("scroll-padding-top", `${mainHeader.clientHeight}px`)
  })

  resizeObserver.observe(mainHeader)
}
