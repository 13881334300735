export {}

const mobileQuery = window.matchMedia("(max-width: 47.999em)")
const megaMenuContent = document.querySelector(".mega-menu__content")
const footerContentSelector = ".footer__top-content"
const footerContent = document.querySelector(`.footer__top ${footerContentSelector}`)
const isFooterContentClone = () => !!megaMenuContent?.querySelector(footerContentSelector)
const removeFooterClone = () => megaMenuContent?.querySelector(footerContentSelector)?.remove()

const handleMobileChange = (mediaQueryList: MediaQueryListEvent | MediaQueryList) => {
  if (mediaQueryList.matches) {
    // Its Mobile
    if (isFooterContentClone()) return
    if (!footerContent || !megaMenuContent) return

    const footerContentClone = footerContent.cloneNode(true)
    megaMenuContent.appendChild(footerContentClone)
  } else {
    // Its Desktop
    removeFooterClone()
  }
}

mobileQuery.addEventListener("change", handleMobileChange)
handleMobileChange(mobileQuery) // Init
