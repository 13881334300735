const regions = document.querySelectorAll<HTMLElement>(".regions-map__region")

regions.forEach(region => {
  region.addEventListener("click", (event) => {
    event.preventDefault()

    const url = region.getAttribute("data-url")

    if (!url) return

    window.location.href = url
  })
})
